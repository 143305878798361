(function($) {
    
/*
    if(document.getElementById('slider-dropdown')){
        //$( "#slider-dropdown" ).removeClass( "select2-hidden-accessible" );

        $( "#slider-dropdown" ).change(function() {
            //get items and update push state
            //var sliderLink = document.getElementById('slider-dropdown').value;
            var option = $('option:selected', this);
            var $title = option.attr('data-title');
            var $permalink = option.attr('data-permalink');
            var slickId = option.attr('data-slickId');
            $( '.slider-tabpanel' ).slick('slickGoTo', parseInt(slickId));
            console.log($title);
            console.log($permalink);
            var $url = new URL($permalink);
            if(!simplisticsFromPopstate){
            history.pushState({slickId: slickId, permalink: $permalink}, $title, $url);
            }
            simplisticsFromPopstate = false;

            $('.tab-list .tab-list-link').removeClass('active');
            $('.tab-list #tabLink'+slickId).addClass('active');

            //console.log(option);
            //window.location.href = sliderLink;
          });

    }
*/

})(jQuery);

(function($) {



  $('.filter_subcategory_event').click(function() {
    if($("#nav-subcategories").is(":visible")){
      $("#nav-subcategories").hide();
      $(this).closest('a.filter_subcategory_event').children('i').removeClass('fa-chevron-up').addClass('fa-chevron-down');

    }else{
      console.log($(this).parent().find('.subcategory_list_filter[style*="display: none"]'));
      if($(this).parent().find('.subcategory_list_filter').length > $(this).parent().find('.subcategory_list_filter[style*="display: none"]').length){
          $("#nav-subcategories").show();
          $(this).closest('a.filter_subcategory_event').children('i').removeClass('fa-chevron-down').addClass('fa-chevron-up');
      }

    }
  });

  $('.filter_category_event').click(function() {
    if($("#nav-categories").is(":visible")){
      $("#nav-categories").hide();
    }else{
      $("#nav-categories").show();
    }
  });

  $('.category_title').click(function(event) {
    $('.category_title').removeClass('active');
    $('.subcategory_list_filter').removeClass('active');
    $('.parent_category').hide();
    $('.parent_category_'+$(event.target).attr('data-target')).show();
    if($(event.target).attr('data-target')=='all'){
      $('.parent_category').show();
    }
    hideResources(event);
  });

  $('.subcategory_list_filter').click(function(event) {
    $('.subcategory_list_filter').removeClass('active');
    hideResources(event);
  });

  $('.thumbnail_event').click(function(event) {
    if($(event.currentTarget).find('img').is(":visible")){
      $(event.currentTarget).find('img').hide();
      $(event.currentTarget).find('iframe').show();
    }
  });

  function hideResources(event){
    $('.category_title[data-target='+$(event.target).attr('data-target')+']').addClass('active');
    $(event.target).addClass('active');
    $('.resource_gallery').hide();
    $('.resource_gallery.category_'+$(event.target).attr('data-target')).show();
    if($('.resource_gallery.category_'+$(event.target).attr('data-target')).length===0){
      $('.no_result_gallery').show();
    }else{
      $('.no_result_gallery').hide();
    }
    $('.btn_load_more_container').remove();
    if($(event.target).attr('data-target')=='all'){
      setTimeout(function(){       
        $('.resources_videos_gallery').btnLoadmore({
          showItem : 12,
          textBtn: 'Load More'
        });
        $('.resources_photos_gallery').btnLoadmore({
          showItem : 12,
          textBtn: 'Load More'
        });
      }, 500);
    }
  }
})(jQuery);

(function($) {


    if($('.slider-pills-container .tab-list2 ')){


        $('.slider-pills-container .tab-list2 .tab-list-link').click(function(){
            /*var slideId = $(this).attr('data-slide');
            var panelId = 'panel'+slideId;
            //remove active class from all .tab-list-link
            $('.slider-pills-container .tab-list .tab-list-link').removeClass('active');
            $(this).addClass('active');

            $('.picture-above-card-container').addClass('picture-above-card-container--display-none');
            $('#'+panelId).removeClass('picture-above-card-container--display-none');

            //updated selected index 
            //$(".slider-pills-container .slider-dropdown").prop('selectedIndex',parseInt(slideId));
            //console.log($(".slider-pills-container .slider-dropdown"));*/
            
            var dataTitle = $(this).attr('data-title');
            var dataParentSection = $(this).attr('data-parentsection');
            $("#"+dataParentSection+" .slider-pills-container .slider-dropdown").val(dataTitle).change();




        });


        //add functionality for arrow actions
        $('.slider-pills-container .tab-arrow--prev').click(function(){
            //get current index 
            //if less than or equal to 1 then will be length
            var dataParentSection = $(this).attr('data-parentsection');
            var currentActivePanel = $('#'+dataParentSection+' .slider-pills-container .tab-list2 .tab-list-link.active').index();
            var newActivePanel = currentActivePanel - 1;
            if(currentActivePanel <= 1){
                newActivePanel = $('#'+dataParentSection+' .slider-pills-container .tab-list2 .tab-list-link').length;
            }

            // minus 1 because the id are using incrementor that started with 0
            newActivePanel = newActivePanel - 1;
            //TODO: put these in resusable function 
            /*var slideId = $('#tabListLink'+newActivePanel).attr('data-slide');
            var panelId = 'panel'+slideId;

            $('.slider-pills-container .tab-list .tab-list-link').removeClass('active');
            $('#tabListLink'+newActivePanel).addClass('active');
            
    
            $('.picture-above-card-container').addClass('picture-above-card-container--display-none');
            $('#'+panelId).removeClass('picture-above-card-container--display-none');*/

            var dataTitle = $('#'+dataParentSection+' #tabListLink'+newActivePanel+dataParentSection).attr('data-title');
            $("#"+dataParentSection+" .slider-pills-container .slider-dropdown").val(dataTitle).change();
            
        });

        $('.slider-pills-container .tab-arrow--next').click(function(){
        
            //get current index 
            //if equal to length then change to 1 -> start
            var dataParentSection = $(this).attr('data-parentsection');
            
            var currentActivePanel = $('#'+dataParentSection+' .slider-pills-container .tab-list2 .tab-list-link.active').index();
            
            // not minusing because the tab id starts at 0 which is 1 fewer than jquery index and length function
            var newActivePanel = currentActivePanel;
            if(currentActivePanel >= $('#'+dataParentSection+' .slider-pills-container .tab-list2 .tab-list-link').length){
                newActivePanel = 0;
            }
            

            //TODO: put these in resusable function 
            /*var slideId = $('#tabListLink'+newActivePanel).attr('data-slide');
            var panelId = 'panel'+slideId;

            $('.slider-pills-container .tab-list .tab-list-link').removeClass('active');
            $('#tabListLink'+newActivePanel).addClass('active');
            
    
            $('.picture-above-card-container').addClass('picture-above-card-container--display-none');
            $('#'+panelId).removeClass('picture-above-card-container--display-none');*/

            var dataTitle = $('#'+dataParentSection+' #tabListLink'+newActivePanel+dataParentSection).attr('data-title');
            $("#"+dataParentSection+" .slider-pills-container .slider-dropdown").val(dataTitle).change();


        });

        $('.slider-pills-container .slider-dropdown').change(function(){
            //update slide
            var slideId = $(this).find(':selected').attr('data-slide');
            var dataParentSection = $(this).find(':selected').attr('data-parentsection');
            var panelId = 'panel'+slideId+dataParentSection;
            

            //update active and inactive slide
            $('#'+dataParentSection+' .picture-above-card-container').addClass('picture-above-card-container--display-none');
            $('#'+dataParentSection+' #'+panelId).removeClass('picture-above-card-container--display-none');

            //remove active class from all .tab-list-link
            $('#'+dataParentSection+' .slider-pills-container .tab-list2 .tab-list-link').removeClass('active');

            var selectedIndex = $(this).find(':selected').index();
            $('#'+dataParentSection+' #tabListLink'+selectedIndex+dataParentSection).addClass('active');
            console.log( '#'+dataParentSection+' #tabListLink'+selectedIndex+dataParentSection);


        });


    }




})(jQuery);
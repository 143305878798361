(function($) {
    var simplisticsFromPopstate = false;
    


    if($('.slider-tabpanel').length > 0 &&  $(".template-tab-list").length > 0){

        $(".template-tab-list .tab-list-links .tab-list-link ").click(function(e){

            e.preventDefault();
            var slickId = $(this).attr("data-slickId");
            $("#slider-dropdown").val(slickId).change();
            
        });
    
        window.addEventListener('popstate', function(e){
            var slickId = e.state.slickId;
            $( '.slider-tabpanel' ).slick('slickGoTo', parseInt(slickId));
            simplisticsFromPopstate = true;
            $("#slider-dropdown").val(parseInt(slickId)).change();
        });

        //next and previous buttons
        $('.template-tab-list #tab-arrow-prev').click(function(e){
            e.preventDefault();
            var $selectedItem = $( "#slider-dropdown option:selected" );
            var $length = $( "#slider-dropdown option" ).length;
            
            if($length <= 1){
                return;
            }

            var $selectedSlide = parseInt($selectedItem.val());

            if($selectedSlide === 0) {
                $selectedSlide = $length - 1;
            }
            else{
                $selectedSlide = $selectedSlide - 1
            }

            $("#slider-dropdown").val($selectedSlide).change();
            
            
        });

        $('.template-tab-list #tab-arrow-next').click(function(e){
           
            e.preventDefault();
            var $selectedItem = $( "#slider-dropdown option:selected" );
            var $length = $( "#slider-dropdown option" ).length;

            if($length <= 1){
                return;
            }

            //this is for next
            var $selectedSlide = parseInt($selectedItem.val());
            if($selectedSlide === ($length-1)) {
                //alert("End of stack");
                $selectedSlide = 0;
            }
            else{
                $selectedSlide = $selectedSlide + 1;
            }

            $("#slider-dropdown").val($selectedSlide).change();

        });


        $( '.slider-tabpanel' ).slick('slickGoTo', simplisticsActiveTemplateSlide);

        if(document.getElementById('slider-dropdown')){
        
            $( "#slider-dropdown" ).change(function() {
                //get items and update push state
                var option = $('option:selected', this);
                var $title = option.attr('data-title');
                var $permalink = option.attr('data-permalink');
                console.log(option);
                var slickId = option.attr('data-slickId');
                $( '.slider-tabpanel' ).slick('slickGoTo', parseInt(slickId));
                var $url = new URL($permalink);
                if(!simplisticsFromPopstate){
                    history.pushState({slickId: slickId, permalink: $permalink}, $title, $url);
                }
                simplisticsFromPopstate = false;
        
                $('.tab-list .tab-list-link').removeClass('active');
                $('.tab-list #tabLink'+slickId).addClass('active');
        

            });
        
        }




    }



    


})(jQuery);





(function($) {

    if($('.accordion-cards .collapsible-controller')){
        $('.accordion-cards .collapsible-controller').click(function(event){
            console.log($( this ).attr("aria-expanded"));
            if($( this ).attr("aria-expanded") === 'true'){
                $( this ).parent().removeClass('accordion-item--full-height');
            }
            else{
                $( this ).parent().addClass('accordion-item--full-height');
            }
        });
    }

})(jQuery);
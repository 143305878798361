(function($) {

    if($('.gform_wrapper .gfield_checkbox .gchoice')){

        function displayToolTip(){
            //alert('hi');
            console.log($('.tooltip').length);
            
            if($(this).siblings('.tooltip').css('display') === 'block'){
                
                $(this).siblings('.tooltip').css('display', 'none');
                $('.tooltip').css('display', 'none');
            }else{
                $('.tooltip').css('display', 'none');
                $(this).siblings('.tooltip').css('display', 'block');
            }
            


        }
        var showTooltip = function(tooltipMessage, containerClass) {
            //$('div.tooltip').remove();
            if(containerClass){
                
                $('<div role="button" tabindex="0" class="info-icon""><i class="fas fa-question-circle"></i></div>').click(displayToolTip).appendTo(containerClass);
                $('<div class="tooltip">'+tooltipMessage+'</div>') .appendTo(containerClass);


            }
            
            //changeTooltipPosition(event);
        };

        let searchParams = new URLSearchParams(window.location.search);

        if(searchParams.has('lang') ){
            if(searchParams.get('lang') === 'fr'){

                //french tooltips

            }
            else if(searchParams.get('lang') === 'sp'){
                //spanish tooltips
                

            }
            else{

                //english tooltips
                showTooltip('Rollforming Solutions cover turnkey rollforming equipment including, but not limited to, uncoilers, coil cars, flatteners, roll form dies, rollformers, punch and cut-off presses, and material handling solutions', '.gfield_radio .gchoice:nth-child(1)');

                showTooltip('Fabrication Solution covers all your metal sheet fabrication needs including, but not limited to, laser cutting, CNC brake press forming, finishing, and design & engineering.', '.gfield_radio .gchoice:nth-child(2)');
        
                showTooltip('Custom Rollforming covers all your customized manufacturing needs. With our in-house rollforming machines, we can  assist in your production of consistently accurate rollformed parts.', '.gfield_radio .gchoice:nth-child(3)');
        
                showTooltip('When it’s time for repairs or scheduled maintenance, stick with Samco Machinery\'s Genuine Parts, installed by the experts.', '.gfield_radio .gchoice:nth-child(4)');

            }


        }
        else{

            //english tooltips
            showTooltip('Rollforming Solutions cover turnkey rollforming equipment including, but not limited to, uncoilers, coil cars, flatteners, roll form dies, rollformers, punch and cut-off presses, and material handling solutions.', '.gfield_radio .gchoice:nth-child(1)');

            showTooltip('Fabrication Solution covers all your metal sheet fabrication needs including, but not limited to, laser cutting, CNC brake press forming, finishing, and design & engineering.', '.gfield_radio .gchoice:nth-child(2)');
    
            showTooltip('Custom Rollforming covers all your customized manufacturing needs. With our in-house rollforming machines, we can  assist in your production of consistently accurate rollformed parts.', '.gfield_radio .gchoice:nth-child(3)');
    
            showTooltip('When it’s time for repairs or scheduled maintenance, stick with Samco Machinery\'s Genuine Parts, installed by the experts.', '.gfield_radio .gchoice:nth-child(4)');

        }


    }


})(jQuery);
(function($) {

   /*function hideEle(element){
        alert('hello');
        console.log(element.css('display'));
        element.css('display', 'none')
    }
 

    if($('.astm-search-menu.is-menu.is-dropdown.menu-item a svg')){
        $('.astm-search-menu.is-menu.is-dropdown.menu-item a svg').click(function(event){
           //console.log($(this).closest('form.is-search-form'));
           console.log($(this).parent().siblings('form.is-search-form').css('display'));
            //alert('hi');
            var element = $(this).parent().siblings('form.is-search-form');
            if($(this).parent().siblings('form.is-search-form').css('display') === 'block'){
                //$(this).parent().siblings('form.is-search-form').css('display', 'none')
                //setTimeout(hideEle(element), 500);
                
                hideEle(element);
                event.stopPropagation();
                
            }


        });

        
    }*/


//is-search-form is-form-style is-form-style-3 is-form-id-0 
//.is-menu a svg
function toggleBtn(ele) {
	ele.setAttribute(
		"aria-pressed",
		ele.getAttribute("aria-pressed") === "true" ? "false" : "true"
	);
}

    if($('.astm-search-menu.is-menu.is-dropdown.menu-item .search-close')){
        $('.astm-search-menu.is-menu.is-dropdown.menu-item .search-close').attr("tabindex", "0");
        $('.astm-search-menu.is-menu.is-dropdown.menu-item .search-close').attr("role", "button");
        $('.astm-search-menu.is-menu.is-dropdown.menu-item .search-close').attr("aria-pressed", "false");

        $('.astm-search-menu.is-menu.is-dropdown.menu-item .search-close').keydown(function(e){
            if (e.key === " " || e.key === "Enter" || e.key === "Spacebar") {

                $(this).siblings('.is-search-form').css('display', 'none');
                toggleBtn(e.target);
            }

        });
    }

})(jQuery);
(function($) {

    const default_arrows = {
        left: '<button class="slick-prev"><i class="fas fa-chevron-left"></i><span class="sr-only">Previous</span></button>',
        right: '<button class="slick-next"><i class="fas fa-chevron-right"></i><span class="sr-only">Next</span></button>',
    };
    
    $('.slider-for').slick({
        accessibility: true,
        vertical: true,
        verticalSwiping: true,
        arrows: false,
        infinite: true,
        autoplaySpeed: 5000,
        mobileFirst: true, 
        responsive: [
            {
               breakpoint: 780,
               settings:{
                   dots: true,
               }
            }
        ]
    });

    $('.slider-for-icon-links').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: default_arrows.left,
        nextArrow: default_arrows.right,
        mobileFirst: true, 
        responsive: [
            {
                breakpoint: 340,
                settings:{
                     slidesToShow: 2
                }
             },
            {
                breakpoint: 767,
                settings:{
                     slidesToShow: 3
                }
             },
            {
               breakpoint: 992,
               settings:{
                    slidesToShow: 3
               }
            },
            {
                breakpoint: 1200,
                settings:{
                     slidesToShow: 5
                }
             },


        ]
    });

    $('.posts').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: default_arrows.left,
        nextArrow: default_arrows.right,
        mobileFirst: true, 
        responsive: [

            {
               breakpoint: 767,
               settings:{
                    slidesToShow: 2
               }
            }
        ]
    });


    $('.product-lists').slick({

        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: default_arrows.left,
        nextArrow: default_arrows.right,
        mobileFirst: true, 
        infinite: true,
        speed: 300,
        responsive: [

            {
               breakpoint: 768,
               settings:{
                    slidesToShow: 2
               }
            },
            {
                breakpoint: 1200,
                settings:{
                     slidesToShow: 3
                }
             },
        ]

    });


    var args = {
        
        speed: 300,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: default_arrows.left,
        nextArrow: default_arrows.right,
        mobileFirst: true, 
        responsive: [
            {
                breakpoint: ThemeJS.Variables.Screen_Sizes.sm,
                settings:{
                     slidesToShow: 2
                }
             },
            {
                breakpoint: ThemeJS.Variables.Screen_Sizes.md,
                settings:{
                     slidesToShow: 3
                }
             },
            {
               breakpoint: ThemeJS.Variables.Screen_Sizes.lg,
               settings:{
                    slidesToShow: 3
               }
            },
            {
                breakpoint: ThemeJS.Variables.Screen_Sizes.xl,
                settings:{
                     slidesToShow: 5
                }
             },


        ]
    };

    $('.slider-ribbon.slider-num2').slick( args );
    $('.resource_photo_gallery_categories-contain').slick( args );
    $('.resource_video_gallery_categories-contain').slick( args );
    const listItem = document.getElementById( simplisticsInitialSlide );
    const indexToGet = $('.slider-ribbon.slider-num1 li').index(listItem);
    
    var args = {
        
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        //infinite: false,
        arrows: true,
        prevArrow: default_arrows.left,
        nextArrow: default_arrows.right,
        mobileFirst: true, 
        //initialSlide: indexToGet,
        initialSlide: indexToGet,
        responsive: [
            {
                breakpoint: ThemeJS.Variables.Screen_Sizes.sm,
                settings:{
                     slidesToShow: 2
                }
             },
            {
                breakpoint: ThemeJS.Variables.Screen_Sizes.md,
                settings:{
                     slidesToShow: 3
                }
             },
            {
               breakpoint: ThemeJS.Variables.Screen_Sizes.lg,
               settings:{
                    slidesToShow: 3
               }
            },
            {
                breakpoint: ThemeJS.Variables.Screen_Sizes.xl,
                settings:{
                     slidesToShow: 5
                }
             },


        ]
    };
    $('.slider-ribbon.slider-num1').slick( args );
    
    $('.tab-list-slider').slick( args );


    $('.slider-tabpanel').slick({
        fade:true,
        dots: false,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        centerMode: false,
        draggable: false,
    });

    var args = {
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 2,
        mobileFirst: true,
        arrows: true,
        prevArrow: default_arrows.left,
        nextArrow: default_arrows.right,
        responsive: [
        {
            breakpoint: ThemeJS.Variables.Screen_Sizes.sm,
            settings: "unslick"
        }]
    };

    $('.gallery-slider').slick( args );
        $( window ).on( 'breakpoint_changed', function () {
            $('.gallery-slider').slick( args );
    });


    $('.big_slider_content').slick({
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: default_arrows.left,
        nextArrow: default_arrows.right,
        mobileFirst: true, 
    });



    //remove tabs from all of the slides
    $(".slick-slide").removeAttr("tabindex");

})(jQuery);



(function($) {

    function toggleFooterAccordion(id, btnId){

        $( "#"+id ).toggleClass( "display-content--mobile");

        if($("#"+btnId).find('i').hasClass('fa-plus')){
            $("#"+btnId).find('i').removeClass('fa-plus');
            $("#"+btnId).find('i').addClass('fa-minus');
            
        }else{

            $("#"+btnId).find('i').removeClass('fa-minus');
            $("#"+btnId).find('i').addClass('fa-plus');

        }
        

    }

    if(document.getElementById('expandCompanyBtn')){
        document.getElementById('expandCompanyBtn').addEventListener('click',function(){
            toggleFooterAccordion('company-links-box', 'expandCompanyBtn');
            
            
        });
    }


    if(document.getElementById('expandSolutionsBtn')){
        document.getElementById('expandSolutionsBtn').addEventListener('click',function(){
            toggleFooterAccordion('solutions-links-box', 'expandSolutionsBtn');
        });

    }


    if(document.getElementById('expandCertifiedBtn')){
        document.getElementById('expandCertifiedBtn').addEventListener('click',function(){
            toggleFooterAccordion('certified-links-box', 'expandCertifiedBtn');
        });
    
    }

    if(document.getElementById('expandSubscribeBtn')){
        document.getElementById('expandSubscribeBtn').addEventListener('click',function(){
            toggleFooterAccordion('subscribe-links-box', 'expandSubscribeBtn');
        });
    }



})(jQuery);


(function($) {


    if($('.slider-template-dropdown  .slider-dropdown')){
        //$( "#slider-dropdown" ).removeClass( "select2-hidden-accessible" );

        $( ".slider-template-dropdown  .slider-dropdown" ).change(function() {
            //var sliderLink = $(this).value;
            console.log(document.getElementById('slider-template-dropdown').value);
            window.location.href = document.getElementById('slider-template-dropdown').value
          });

    }


})(jQuery);
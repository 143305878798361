jQuery( function ( $ ) {
    const $body = $( 'body' );
    const $header = $( '.header' );
    const $toggle = $( '#menuToggle' );
    const navbarMobileMenu = $("#navbarMobileMenu");


    // create focus trap
    const focus_trap = focusTrap.createFocusTrap( $header.get(0), {
        onActivate () {
            //$toggle.addClass( 'shifted' );
            //$header.addClass( 'shifted' );
            //$body.addClass( 'menu-open' );
        },
        onDeactivate () {
           // $toggle.removeClass( 'is-active' );
            //$header.removeClass( 'shifted' );
            //$body.removeClass( 'menu-open' );
            /*setTimeout( function () {
                $menu.removeClass( 'visible' );
            }, speed );*/
        },
        initialFocus: $toggle.get(0),
    });




    // toggle mobile menu on hamburger click
    $toggle.click( () => {
        $body.hasClass( 'menu-open' ) ? focus_trap.deactivate() : focus_trap.activate();
    });





});
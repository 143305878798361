(function ($) {
  function unshift(menuWrap, body) {
    menuWrap.removeClass("shifted");
    body.removeClass("menu-open");
    $("ul.sub-menu.shifted").each(function () {
      $(this).removeClass("shifted");
    });
  }
  function fixMenuSpacing(element) {
    if ($(window).width() < 1200) {
      var rt =
        $(window).width() - (element.offset().left + element.outerWidth());
      $(".menu-wrap").css("padding-left", rt + "px");
      $(".menu-wrap .sub-menu").css("padding-left", rt + "px");
    } else {
      $(".menu-wrap").css("padding-left", 23 + "px");
      $(".menu-wrap .sub-menu").css("padding-left", 23 + "px");

      let body = $("body");
      let menuWrap = $(".menu-wrap");
      if (menuWrap.hasClass("shifted")) {
        unshift(menuWrap, body);
      }
    }
  }
  $(function () {
    let body = $("body");

    jQuery(".menu-wrap #menu-header-menu > li.menu-item-has-children > a").after(
      "<button class='mobile-navigation-btn' title='Open Sub Menu' next-page><i class='fas fa-chevron-right'></i></button>"
    );

    $("#navbarMobileMenu #menu-header-menu > li > ul.sub-menu").prepend(
      "<button class='mobile-navigation-btn' title='Go Back' back-button><i class='fas fa-chevron-left'></i></button>"
    );

    let focus_trapSubMenu;
    $("button[next-page]").click(function () {
      $(this).siblings("ul.sub-menu").addClass("shifted");
      $(this).siblings("ul.sub-menu").css("display", "block");
      var thisElement = $(this);
      focus_trapSubMenu = focusTrap.createFocusTrap( $(this).siblings("ul.sub-menu").get(0), {
        onActivate () {
          console.log(thisElement);

          thisElement.addClass('focus-active');
        },
        onDeactivate () {
          thisElement.siblings("ul.sub-menu").removeClass("shifted");
          thisElement.siblings("ul.sub-menu").css("display", "none");
          thisElement.removeClass('focus-active');
        }
      });

      thisElement.hasClass('focus-active') ? focus_trapSubMenu.deactivate() :focus_trapSubMenu.activate();

    });

    $(".menu-wrap li.menu-item-has-children > a").click(function (e) {
      if ( !$(this).attr("href").includes("http") && !$(this).attr("href").includes("www")) {
        e.preventDefault();
        $(this).siblings("ul.sub-menu").addClass("shifted");
      }
    });

    $("button[back-button]").click(function () {
      $(this).parent("ul.sub-menu.shifted").removeClass("shifted");
      $(this).parent("ul.sub-menu.shifted").css("display", "none");
      $(this).parent("ul.sub-menu.shifted").siblings('mobile-navigation-btn').removeClass("focus-active");
      
      //const focus_trapSubMenu = focusTrap.createFocusTrap( $(this).siblings("ul.sub-menu").get(0));
      focus_trapSubMenu.deactivate();

    });

    $(".menu-toggle").click(function () {
      let menuWrap = $(".menu-wrap");
      if (menuWrap.hasClass("shifted")) {
        unshift(menuWrap, body);
        $('.header-ribbon').css('display', 'block');
      } else {
        menuWrap.addClass("shifted");
        body.addClass("menu-open");
        //hiden ribbon
        $('.header-ribbon').css('display', 'none');
      }
    });

    var $menuToggle = $(".menu-toggle");

    fixMenuSpacing($menuToggle);
    let resizeTimeoutMobile;
    $(window).resize(function () {
      clearTimeout(resizeTimeoutMobile);
      resizeTimeoutMobile = setTimeout(fixMenuSpacing($menuToggle), 250);
    });
  });
})(jQuery);
